// extracted by mini-css-extract-plugin
export var container = "index-module--container--39oC6";
export var menuButton = "index-module--menuButton--E4v0i";
export var rightButtons = "index-module--rightButtons--3EBwG";
export var search = "index-module--search--yJC33";
export var content = "index-module--content--Ve8zG";
export var scrolledClass = "index-module--scrolledClass--3Zmux";
export var leftHidden = "index-module--leftHidden--i2xLz";
export var languageSwitch = "index-module--languageSwitch--39vIQ";
export var rightHidden = "index-module--rightHidden--zUGTS";
export var mainIcon = "index-module--mainIcon--zOpdG";
export var displayed = "index-module--displayed--jmEyk";